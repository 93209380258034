

const pThumbnail = [
    {
        image: 'https://i.ibb.co/PZGSMPB/mood.png',
      name: 'Mood Music',
      description: "Set the mood",
      url:
        'https://mood-music.netlify.app/',
    },
    {
        image: 'https://i.ibb.co/zV09qBK/Coffee.jpg',
      name: 'Coffee Findr',
      description: "An app that helps you find good coffee in a given location. With a page dedicated to educating the user on different types of coffee.",
      url:
        'https://kind-bhabha-f2de49.netlify.app/',
    },
    
]
  
  export default pThumbnail
